import LoginQrCodeCollection from '../auth/collections/LoginQrCodeCollection';
import TenantCollection from '../auth/collections/TenantCollection';
import UserCollection from '../auth/collections/UserCollection';
import { ModelType } from '../base/schemas/BaseSchema';
import NotificationLogCollection from '../common/collections/NotificationLogCollection';
import WorkfieldCollection from '../common/collections/WorkfieldCollection';
import ContactCollection from '../contact/collections/ContactCollection';
import LeadCollection from '../crm/collections/LeadCollection';
import ProductCategoryCollection from '../inventory/collections/ProductCategoryCollection';
import ProductCollection from '../inventory/collections/ProductCollection';
import ProductSectionCollection from '../inventory/collections/ProductSectionCollection';
import ProductUnitCollection from '../inventory/collections/ProductUnitCollection';
import ExpenseCollection from '../invoicing/collections/ExpenseCollection';
import InvoiceAutosaveCollection from '../invoicing/collections/InvoiceAutosaveCollection';
import InvoiceCollection from '../invoicing/collections/InvoiceCollection';
import ModelEventCollection from '../common/collections/ModelEventCollection';
import InvoiceLineCollection from '../invoicing/collections/InvoiceLineCollection';
import InvoicePaymentCollection from '../invoicing/collections/InvoicePaymentCollection';
import InvoiceSectionCollection from '../invoicing/collections/InvoiceSectionCollection';
import InvoicingDocumentsSettingCollection from '../invoicing/collections/InvoicingDocumentsSettingCollection';
import InvoicingNotificationsSettingCollection from '../invoicing/collections/InvoicingNotificationsSettingCollection';
import InvoicingNumberingSettingCollection from '../invoicing/collections/InvoicingNumberingSettingCollection';
import InvoicingPaymentRemindersSettingCollection from '../invoicing/collections/InvoicingPaymentRemindersSettingCollection';
import VatRateCollection from '../invoicing/collections/VatRateCollection';
import PdfCollection from '../pdf/collections/PdfCollection';
import PdfImageCollection from '../pdf/collections/PdfImageCollection';
import CalendarEventCollection from '../planning/collections/CalendarEventCollection';
import TimeActivityCollection from '../planning/collections/TimeActivityCollection';
import TimeActivityGeolocationCollection from '../planning/collections/TimeActivityGeolocationCollection';
import TimeActivityPauseCollection from '../planning/collections/TimeActivityPauseCollection';
import TaskCollection from '../task/collections/TaskCollection';
import WorksiteCollection from '../worksite/collections/WorksiteCollection';
import ModelFileCollection from '../common/collections/ModelFileCollection';
import ModelFileFolderCollection from '../common/collections/ModelFileFolderCollection';
import WorksitePhaseCollection from '../worksite/collections/WorksitePhaseCollection';
import WorksiteProfitabilityDocumentCollection from '../worksite/collections/WorksiteProfitabilityDocumentCollection';
import WorksiteProgressBillingCollection from '../worksite/collections/WorksiteProgressBillingCollection';
import WorksiteProgressBillingLinesCollection from '../worksite/collections/WorksiteProgressBillingLinesCollection';
import SourceCollection from '../crm/collections/SourceCollection';
import ConnectionsCollection from '../common/collections/ConnectionsCollection';
import WasteDataCollection from '../invoicing/collections/ExpenseCollection';
import BankAccountCollection from '~/app/bankaccount/collections/BankAccountCollection';
import BankAccountTransactionCollection from '~/app/bankaccount/collections/BankAccountTransactionCollection';
import InvoiceTemplateCollection from '~/app/invoicing/collections/InvoiceTemplateCollection';
import InvoiceTemplateSectionCollection from '~/app/invoicing/collections/InvoiceTemplateSectionCollection';
import InvoiceTemplateSectionColumnCollection from '~/app/invoicing/collections/InvoiceTemplateSectionColumnCollection';
import InvoiceAnnexPdfCollection from '~/app/invoicing/collections/InvoiceAnnexPdfCollection';
import PlanningItemCollection from '~/app/planning/collections/PlanningItemCollection';
import GlobalSearchItemCollection from '@/app/common/collections/GlobalSearchItemCollection';
import SettingCollection from '@/app/common/collections/SettingCollection';
import ProductImageCollection from '@/app/inventory/collections/ProductImageCollection';
import NotificationCollection from '@/app/common/collections/NotificationCollection';
import TimeActivitySettingCollection from '@/app/planning/collections/TimeActivitySettingCollection';
import ImportedFileCollection from '@/app/common/collections/ImportedFileCollection';
import InvoiceDiscountCollection from '@/app/invoicing/collections/InvoiceDiscountCollection';
import WorksiteClosureReportCollection from '@/app/worksite/collections/WorksiteClosureReportCollection';
import WasteCenterCollection from '@/app/common/collections/WasteCenterCollection';
import TermsOfServiceCollection from '@/app/common/collections/TermsOfServiceCollection';
import TermsOfServiceAcceptationCollection from '@/app/common/collections/TermsOfServiceAcceptationCollection';
import ComposedWorkLineCollection from '@/app/inventory/collections/ComposedWorkLineCollection';
import InvoicePaymentReminderGroupCollection from '../invoicing/collections/InvoicePaymentReminderGroupCollection';

// {{SCAFFOLDING_IMPORT}}

export default class CollectionFactory {
    static make(type: ModelType, schemas: any, included: any, meta: Object = {}) {
        switch (type) {
            case ModelType.TENANT_USERS:
                return UserCollection.fromTenantUsers(schemas, included, meta);
            case ModelType.USERS:
                return new UserCollection(schemas, included, meta);
            case ModelType.TENANTS:
                return new TenantCollection(schemas, included, meta);
            case ModelType.LOGIN_QR_CODES:
                return new LoginQrCodeCollection(schemas, included, meta);

            case ModelType.CONTACTS:
                return new ContactCollection(schemas, included, meta);

            case ModelType.IMPORTED_FILE:
                return new ImportedFileCollection(schemas, included, meta);

            case ModelType.LEADS:
                return new LeadCollection(schemas, included, meta);
            case ModelType.SOURCES:
                return new SourceCollection(schemas, included, meta);

            case ModelType.PRODUCTS:
                return new ProductCollection(schemas, included, meta);
            case ModelType.PRODUCT_UNITS:
                return new ProductUnitCollection(schemas, included, meta);
            case ModelType.PRODUCT_CATEGORIES:
                return new ProductCategoryCollection(schemas, included, meta);
            case ModelType.PRODUCT_SECTIONS:
                return new ProductSectionCollection(schemas, included, meta);

            case ModelType.TASKS:
                return new TaskCollection(schemas, included, meta);
            case ModelType.WASTE_DATAS:
                return new WasteDataCollection(schemas, included, meta);
            case ModelType.EXPENSES:
                return new ExpenseCollection(schemas, included, meta);
            case ModelType.VAT_RATES:
                return new VatRateCollection(schemas, included, meta);
            case ModelType.INVOICES:
                return new InvoiceCollection(schemas, included, meta);
            case ModelType.INVOICE_AUTOSAVES:
                return new InvoiceAutosaveCollection(schemas, included, meta);
            case ModelType.INVOICE_SECTIONS:
                return new InvoiceSectionCollection(schemas, included, meta);
            case ModelType.INVOICE_LINES:
                return new InvoiceLineCollection(schemas, included, meta);
            case ModelType.MODEL_EVENTS:
                return new ModelEventCollection(schemas, included, meta);
            case ModelType.INVOICES_ANNEX_PDFS:
                return new InvoiceAnnexPdfCollection(schemas, included, meta);
            case ModelType.INVOICE_PAYMENTS:
                return new InvoicePaymentCollection(schemas, included, meta);
                case ModelType.INVOICE_PAYMENT_REMINDER_GROUPS:
                    return new InvoicePaymentReminderGroupCollection(schemas, included, meta);
            case ModelType.INVOICE_DISCOUNTS:
                return new InvoiceDiscountCollection(schemas, included, meta);
            case ModelType.INVOICING_NUMBERING_SETTINGS:
                return new InvoicingNumberingSettingCollection(schemas, included, meta);
            case ModelType.INVOICING_DOCUMENTS_SETTINGS:
                return new InvoicingDocumentsSettingCollection(schemas, included, meta);
            case ModelType.INVOICING_NOTIFICATIONS_SETTINGS:
                return new InvoicingNotificationsSettingCollection(schemas, included, meta);
            case ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS:
                return new InvoicingPaymentRemindersSettingCollection(schemas, included, meta);

            case ModelType.TIME_ACTIVITIES:
                return new TimeActivityCollection(schemas, included, meta);
            case ModelType.TIME_ACTIVITY_PAUSES:
                return new TimeActivityPauseCollection(schemas, included, meta);
            case ModelType.TIME_ACTIVITY_GEOLOCATIONS:
                return new TimeActivityGeolocationCollection(schemas, included, meta);
            case ModelType.CALENDAR_EVENTS:
                return new CalendarEventCollection(schemas, included, meta);
            case ModelType.TIME_ACTIVITY_SETTINGS:
                return new TimeActivitySettingCollection(schemas, included, meta);

            case ModelType.WORKSITES:
                return new WorksiteCollection(schemas, included, meta);

            case ModelType.MODEL_FILES:
                return new ModelFileCollection(schemas, included, meta);
            case ModelType.MODEL_FILE_FOLDERS:
                return new ModelFileFolderCollection(schemas, included, meta);
            case ModelType.WORKSITE_PHASES:
                return new WorksitePhaseCollection(schemas, included, meta);
            case ModelType.WORKSITE_PROFITABILITY_DOCUMENTS:
                return new WorksiteProfitabilityDocumentCollection(schemas, included, meta);
            case ModelType.WORKSITE_PROGRESS_BILLINGS:
                return new WorksiteProgressBillingCollection(schemas, included, meta);
            case ModelType.WORKSITE_PROGRESS_BILLING_LINES:
                return new WorksiteProgressBillingLinesCollection(schemas, included, meta);
            case ModelType.WORKSITES_CLOSURE_REPORT:
                return new WorksiteClosureReportCollection(schemas, included, meta);

            case ModelType.PDFS:
                return new PdfCollection(schemas, included, meta);
            case ModelType.PDF_IMAGES:
                return new PdfImageCollection(schemas, included, meta);

            case ModelType.NOTIFICATIONS_LOGS:
                return new NotificationLogCollection(schemas, included, meta);
            case ModelType.WORKFIELDS:
                return new WorkfieldCollection(schemas, included, meta);
            case ModelType.WASTE_CENTER:
                return new WasteCenterCollection(schemas, included, meta);
            case ModelType.BANK_ACCOUNTS:
                return new BankAccountCollection(schemas, included, meta);
            case ModelType.BANK_ACCOUNT_TRANSACTIONS:
                return new BankAccountTransactionCollection(schemas, included, meta);

            case ModelType.INVOICE_TEMPLATES:
                return new InvoiceTemplateCollection(schemas, included, meta);
            case ModelType.INVOICE_TEMPLATE_SECTIONS:
                return new InvoiceTemplateSectionCollection(schemas, included, meta);
            case ModelType.INVOICE_TEMPLATE_SECTION_COLUMNS:
                return new InvoiceTemplateSectionColumnCollection(schemas, included, meta);
            case ModelType.PLANNING_ITEMS:
                return new PlanningItemCollection(schemas, included, meta);
            case ModelType.GLOBAL_SEARCH_ITEMS:
                return new GlobalSearchItemCollection(schemas, included, meta);
            case ModelType.SETTINGS:
                return new SettingCollection(schemas, included, meta);
            case ModelType.CONNECTIONS:
                return new ConnectionsCollection(schemas, included, meta);
            case ModelType.PRODUCT_IMAGES:
                return new ProductImageCollection(schemas, included, meta);
            case ModelType.NOTIFICATIONS:
                return new NotificationCollection(schemas, included, meta);
            case ModelType.TERMS_OF_SERVICES:
                return new TermsOfServiceCollection(schemas, included, meta);
            case ModelType.TERMS_OF_SERVICE_ACCEPTATIONS:
                return new TermsOfServiceAcceptationCollection(schemas, included, meta);

            case ModelType.COMPOSED_WORK_LINES:
                return new ComposedWorkLineCollection(schemas, included, meta);
            // {{SCAFFOLDING}}
        }

        throw new Error(`CollectionFactory: No collection found for type '${type}'.`);
    }
}
