import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoicePaymentReminderGroup extends BaseModel {
    type: ModelType = ModelType.INVOICE_PAYMENT_REMINDER_GROUPS;
    static type: ModelType = ModelType.INVOICE_PAYMENT_REMINDER_GROUPS;
    prefix: string = 'invoicing:invoice-payment-reminder-groups';
    static prefix: string = 'invoicing:invoice-payment-reminder-groups';

    get title(): string {
        return this.attributes.title;
    }

    get default(): boolean {
        return this.attributes.default;
    }
}
