import GlobalSearchItemCollection from '../collections/GlobalSearchItemCollection';
import GlobalSearchItem from '../models/GlobalSearchItem';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '@/app/base/schemas/BaseSchema';

export default class ModelEventEndpoint extends BaseEndpoint<GlobalSearchItem, GlobalSearchItemCollection> {
    model: ModelType = ModelType.MODEL_EVENTS;
    path = 'tenant/common/model-events';
    include = ' user,responsibleModel';
}
