export type Enum<TEnum extends object> = TEnum[keyof TEnum];

export const TENANT_SUBSCRIPTIONL_PLAN = {
    STARTER: 'starter',
    COMBO_ESSENTIAL: 'combo-essential',
    PRO: 'pro',
    COMBO_PRO: 'combo-pro',
    ENTERPRISE: 'enterprise',
    COMBO_ENTERPRISE: 'combo-enterprise',
    GROWTH: 'growth',
} as const;

export const TENANT_SUBSCRIPTION_PLAN_CONFIG = {
    [TENANT_SUBSCRIPTIONL_PLAN.STARTER]: {
        key: TENANT_SUBSCRIPTIONL_PLAN.STARTER,
        name: TENANT_SUBSCRIPTIONL_PLAN.STARTER,
        benefits: [],
        upsellPlans: [TENANT_SUBSCRIPTIONL_PLAN.PRO, TENANT_SUBSCRIPTIONL_PLAN.ENTERPRISE, TENANT_SUBSCRIPTIONL_PLAN.GROWTH],
        isCombo: false,
    },
    [TENANT_SUBSCRIPTIONL_PLAN.COMBO_ESSENTIAL]: {
        key: TENANT_SUBSCRIPTIONL_PLAN.COMBO_ESSENTIAL,
        name: TENANT_SUBSCRIPTIONL_PLAN.STARTER,
        benefits: [],
        upsellPlans: [TENANT_SUBSCRIPTIONL_PLAN.COMBO_PRO, TENANT_SUBSCRIPTIONL_PLAN.COMBO_ENTERPRISE],
        isCombo: true,
    },
    [TENANT_SUBSCRIPTIONL_PLAN.PRO]: {
        key: TENANT_SUBSCRIPTIONL_PLAN.PRO,
        name: TENANT_SUBSCRIPTIONL_PLAN.PRO,
        benefits: ['composed_works', 'worksite_closure_reports', 'planning', 'crm'],
        upsellPlans: [TENANT_SUBSCRIPTIONL_PLAN.ENTERPRISE, TENANT_SUBSCRIPTIONL_PLAN.GROWTH],
        isCombo: false,
    },
    [TENANT_SUBSCRIPTIONL_PLAN.COMBO_PRO]: {
        key: TENANT_SUBSCRIPTIONL_PLAN.COMBO_PRO,
        name: TENANT_SUBSCRIPTIONL_PLAN.PRO,
        benefits: ['progress_billings', 'profitability', 'documents', 'time_sheets'],
        upsellPlans: [],
        isCombo: true,
    },
    [TENANT_SUBSCRIPTIONL_PLAN.ENTERPRISE]: {
        key: TENANT_SUBSCRIPTIONL_PLAN.ENTERPRISE,
        name: TENANT_SUBSCRIPTIONL_PLAN.ENTERPRISE,
        benefits: ['progress_billings', 'profitability', 'documents', 'time_sheets'],
        upsellPlans: [TENANT_SUBSCRIPTIONL_PLAN.GROWTH],
        isCombo: false,
    },
    [TENANT_SUBSCRIPTIONL_PLAN.COMBO_ENTERPRISE]: {
        key: TENANT_SUBSCRIPTIONL_PLAN.COMBO_ENTERPRISE,
        name: TENANT_SUBSCRIPTIONL_PLAN.ENTERPRISE,
        benefits: ['progress_billings', 'profitability', 'documents', 'time_sheets'],
        upsellPlans: [],
        isCombo: true,
    },
    [TENANT_SUBSCRIPTIONL_PLAN.GROWTH]: {
        key: TENANT_SUBSCRIPTIONL_PLAN.GROWTH,
        name: TENANT_SUBSCRIPTIONL_PLAN.GROWTH,
        benefits: [],
        upsellPlans: [],
        isCombo: false,
    },
} as const;

export type SubscriptionPlan = Enum<typeof TENANT_SUBSCRIPTIONL_PLAN>;
export type SubscriptionPlanConfig = Enum<typeof TENANT_SUBSCRIPTION_PLAN_CONFIG>;
