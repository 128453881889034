import InvoicingPaymentRemindersSettingCollection from '../collections/InvoicingPaymentRemindersSettingCollection';
import InvoicingPaymentRemindersSetting from '../models/InvoicingPaymentRemindersSetting';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoicingPaymentRemindersSettingEndpoint extends BaseEndpoint<InvoicingPaymentRemindersSetting, InvoicingPaymentRemindersSettingCollection> {
    model: ModelType = ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS;
    path: string = 'tenant/invoicing/invoicing-payment-reminders-settings';
    include = 'invoicePaymentReminderGroup';
}
